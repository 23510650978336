import axios from 'axios'
import Util from "../../Util.js"

var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL

export default class RestResource {
    async fetchUsers(params) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/fetchUsers`
        return await axios.post(url, params, Util.setupHeaders())
    }

    async getUniqueCountries() {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/getUniqueCountries`
        return await axios.get(url, Util.setupHeaders())
    }

    async getUsers(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/getUsers`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getUserByEmail(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/findUserByText`
        return await axios.post(url, data, Util.setupHeaders())
    }
    
    async getUserById(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/findUserById`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getTasks(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/getTasks`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getAssignedTasks(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/getAssignedTasks`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getTaskData(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/getTaskData`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async notifyTaskCompletion(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/notifyTaskCompletion`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getReceiptData(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/receiptData`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateReceiptStatus(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/updateReceiptStatus`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async createTask(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/createTask`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getCashoutData(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/cashoutData`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async cashoutDataExport(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/export`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getDealsData(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/getAllDeals`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateDealStatus(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/updateDealStatus`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async assignTaskToUser(data) {
        const url = `${ADMIN_BACKEND_URL}/mobilev2/assignTask`
        return await axios.post(url, data, Util.setupHeaders())
    }
}